
import { defineComponent, ref, useFetch, useStore } from '@nuxtjs/composition-api'
import { setupSiteConfig } from '~/assets/craft'

export default defineComponent({
  props: {
    site: { type: String, required: false, default: undefined },
    uri: { type: String, required: true },
  },
  setup(props, { emit }) {
    const store = useStore()
    const state = ref('loading')
    const page = ref(null)

    let site = props.site
    if (!site) {
      const { site: fallbackSite } = setupSiteConfig()
      site = fallbackSite
    }

    useFetch(async () => {
      try {
        page.value = await store.dispatch('page/fetchUri', { site, uri: props.uri })
        state.value = page.value ? 'ready' : 'error'
      } catch (error) {
        emit('error', { uri: props.uri, site: props.site, error })
        state.value = 'error'
      }
    })

    return {
      page,
      state,
    }
  },
})
