import { render, staticRenderFns } from "./ImageCarousel.vue?vue&type=template&id=2c61824c&scoped=true"
import script from "./ImageCarousel.vue?vue&type=script&lang=js"
export * from "./ImageCarousel.vue?vue&type=script&lang=js"
import style0 from "./ImageCarousel.vue?vue&type=style&index=0&id=2c61824c&prod&lang=scss"
import style1 from "./ImageCarousel.vue?vue&type=style&index=1&id=2c61824c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c61824c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomIconArrowLeft: require('/home/userapp/components/Atom/Icon/IconArrowLeft.vue').default,AtomIconArrowRight: require('/home/userapp/components/Atom/Icon/IconArrowRight.vue').default})
