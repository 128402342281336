
import { defineComponent, ref, useContext, useRouter } from '@nuxtjs/composition-api'
import { getUrlSegments } from '~/assets/util'

export default defineComponent({
  props: {
    title: { type: String, required: false, default: undefined },
    subtitle: { type: String, required: false, default: undefined },
    introduction: { type: String, required: false, default: undefined },
    image: { type: Object, required: false, default: undefined },
    url: { type: String, required: true },
    price1: { type: Number, required: true },
    price2: { type: Number, required: false, default: null },
    price3: { type: Number, required: false, default: null },
  },
  setup(props) {
    const { req } = useContext()
    const currentHost = req?.headers.host || window?.location.host || '-'
    const router = useRouter()
    const userCustomAmount = ref(0)
    const chosenFixedAmount = ref(0)
    const chosenIndex = ref(0)
    const customAmount = ref(false)
    const getFirstQueryParamConnector = () => {
      if (props.url.match('[?]')) {
        return '&'
      }

      return '?'
    }
    const buttonType = (amount) => {
      if (amount === chosenFixedAmount.value) {
        return 'tertiary'
      }

      return 'quaternary'
    }
    const customAmountActivate = () => {
      customAmount.value = true
      chosenFixedAmount.value = 0
    }
    const setChosenFixedAmount = (amount, index) => {
      customAmount.value = false
      chosenFixedAmount.value = amount
      chosenIndex.value = index
    }
    const setUserCustomAmount = (amount) => {
      userCustomAmount.value = parseFloat(amount)
    }
    const submitForm = () => {
      let url = null
      const amount = chosenFixedAmount.value || userCustomAmount.value || undefined

      if (amount) {
        url = props.url + getFirstQueryParamConnector() + 'freq=Eenmalig&bedrag=' + 'bedrage' + chosenIndex.value
      }
      if (userCustomAmount.value) {
        url = props.url + getFirstQueryParamConnector() + 'freq=Eenmalig&bedragOpen=' + userCustomAmount.value
      }

      if (url) {
        const { protocol, host, rest } = getUrlSegments(url)
        if ([undefined, 'http:', 'https:'].includes(protocol) && [undefined, currentHost].includes(host)) {
          router.push({
            path: rest,
          })

          return
        }

        window.location.href = url
      }
    }

    return {
      buttonType,
      chosenFixedAmount,
      customAmount,
      customAmountActivate,
      getFirstQueryParamConnector,
      setChosenFixedAmount,
      submitForm,
      setUserCustomAmount,
      userCustomAmount,
    }
  },
})
