
import { defineComponent, ref, watch } from '@nuxtjs/composition-api'

export default defineComponent({
  setup(props, { emit }) {
    const amount = ref(0)

    watch(amount, (newValue) => {
      // do a check for valid or isNan of number type
      emit('valueUpdate', newValue)
    })

    return {
      amount,
    }
  },
})
