
import get from 'lodash/get'
import { defineComponent, ref } from '@nuxtjs/composition-api'
import { Glide, GlideSlide } from 'vue-glide-js'
import { getMediaImage } from '~/assets/media'
import { setupTranslationPrefixer } from '~/assets/util'

//
// For vue-glide documentation, see https://antonreshetov.github.io/vue-glide/props/
//

export default defineComponent({
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  props: {
    addShadow: { type: Boolean, required: false, default: true },
    /**
     * Media is used for a mix of images and youtube videos
     *
     * The media array may contain a mix of the following items
     * - Image: { type: 'image', _lgUri: '...', _lgWidth: 123, _lgHeight: 123, .... }
     * - Youtube: { type: 'youtube', code: '<iframe ... />' }
     * - Entry: { type: 'entry', subtitle: '...', 'introduction': '<p>...</p>', image: { _lgUri: '...', _lgWidth: 123, _lgHeight: 123, .... } }
     */
    media: { type: Array, required: false, default: () => [] },
    /**
     * Entries is used for advanced slides i.e. slides which include a title and description too
     */
    entries: { type: Array, required: false, default: () => [] },
    /**
     * Images is used for simple slides which have a single individual image only
     */
    images: { type: Array, required: false, default: () => [] },
  },
  setup(props) {
    const slider = ref()
    const activeSlide = ref(0)
    const amendedEntries = [
      ...props.media.map((media) => {
        switch (media.type) {
          case 'image':
            return { type: 'image', image: getMediaImage(media) }
          case 'youtube':
            return { type: 'youtube', youtube: media }
          case 'entry':
            return { type: 'entry', subtitle: media.subtitle, introduction: media.introduction, image: getMediaImage(media.image) }
          default:
            return undefined
        }
      }),
      ...props.entries.map((entry) => ({ type: 'entry', subtitle: entry.subtitle, introduction: entry.introduction, image: getMediaImage(entry.image) })),
      ...props.images.map((image) => ({ type: 'image', image: getMediaImage(image) })),
    ].filter((entry) => !!entry)
    return { activeSlide, amendedEntries, get, slider, ...setupTranslationPrefixer('carousel') }
  },
})
