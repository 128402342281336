import { render, staticRenderFns } from "./CarouselSlideStory.vue?vue&type=template&id=0d818a34"
import script from "./CarouselSlideStory.vue?vue&type=script&lang=js"
export * from "./CarouselSlideStory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculePicture: require('/home/userapp/components/Molecule/Picture.vue').default,AtomLinkWithIcon: require('/home/userapp/components/Atom/Link/LinkWithIcon.vue').default,AtomLink: require('/home/userapp/components/Atom/Link/Link.vue').default})
