
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    label: { type: String, required: false, default: undefined },
    description: { type: String, required: false, default: undefined },
    url: { type: String, required: false, default: undefined },
    title: { type: String, required: false, default: undefined },
    buttonLabel: { type: String, required: false, default: undefined },
    entries: { type: Array, required: true },
  },
})
