import { render, staticRenderFns } from "./Donation.vue?vue&type=template&id=b882696e&scoped=true"
import script from "./Donation.vue?vue&type=script&lang=js"
export * from "./Donation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b882696e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomButton: require('/home/userapp/components/Atom/Button.vue').default,MoleculeInputValue: require('/home/userapp/components/Molecule/InputValue.vue').default,AtomIconAppHeart: require('/home/userapp/components/Atom/IconApp/IconAppHeart.vue').default,AtomIconArrowRight: require('/home/userapp/components/Atom/Icon/IconArrowRight.vue').default,AtomLink: require('/home/userapp/components/Atom/Link/Link.vue').default})
